(function() {
    // Store script URL during initialization
    const scriptUrl = document.currentScript ? document.currentScript.src : undefined;

    function getScriptOrigin() {
        if (scriptUrl) {
            return new URL(scriptUrl).origin;
        }
        // Fallback to current page origin if script URL is not available
        return window.location.origin;
    }

    function createIframe(path, containerId) {
        const container = document.getElementById(containerId);
        if (!container) {
            console.warn(`Container element with id "${containerId}" not found`);
            return;
        }

        const iframe = document.createElement('iframe');
        const origin = getScriptOrigin();
        iframe.src = `${origin}${path}`;
        iframe.style.width = '100%';
        iframe.style.border = 'none';
        iframe.style.minHeight = '400px';
        iframe.style.overflow = 'hidden';

        // Add a class for potential styling
        iframe.className = 'embedded-form-iframe';

        // Handle iframe resize messages
        window.addEventListener('message', function(event) {
            // Verify message origin for security
            if (event.origin !== origin) return;

            if (event.data.type === 'contactSubmitted' || event.data.type === 'newsletterSubmitted') {
                const customEvent = new CustomEvent(event.data.type, {
                    detail: {
                        status: event.data.status,
                        message: event.data.message
                    }
                });
                container.dispatchEvent(customEvent);
            }

            // Handle iframe height adjustments
            if (event.data.type === 'resize' && event.data.height) {
                iframe.style.height = `${event.data.height}px`;
            }
        });

        container.appendChild(iframe);
    }

    window.embedContactForm = function(containerId) {
        createIframe('/embed/contact', containerId);
    };

    window.embedNewsletter = function(containerId) {
        createIframe('/embed/newsletter', containerId);
    };
})();
